@charset "UTF-8";
/* keyword */
.review-keyword-box {
  position: relative;
  width: 100%; }
  .review-keyword-box .btn-keyword {
    position: relative;
    display: block;
    margin: 0 0 10px 0; }
    .review-keyword-box .btn-keyword .keyword-del {
      display: inline-block;
      height: 28px;
      padding: 0 8px;
      border: 1px solid #343A40;
      border-radius: 5px;
      text-align: center;
      font-size: 13px;
      font-weight: 400;
      color: #000000;
      box-sizing: border-box; }
    .review-keyword-box .btn-keyword .keyword-add {
      position: absolute;
      top: 50%;
      right: 0;
      margin-top: -14px; }
  .review-keyword-box table thead {
    width: 100%;
    height: 46px;
    background: #EFF2F7;
    text-align: center; }
    .review-keyword-box table thead tr {
      width: 100%; }
      .review-keyword-box table thead tr th {
        font-size: 13px;
        font-weight: 500;
        color: #000000;
        padding: 0 21px;
        border: none; }
  .review-keyword-box table tbody tr {
    width: 100%; }
    .review-keyword-box table tbody tr td {
      font-size: 13px;
      font-weight: 400;
      color: #000000;
      text-align: center; }

/* reviewConnect */
.review-connect-table {
  margin: 0 0 20px 0; }
  .review-connect-table .review-pick-list {
    display: block;
    width: 100%;
    border: 1px solid #E8ECF4;
    border-bottom: none;
    box-sizing: border-box;
    padding: 8px 10px; }
    .review-connect-table .review-pick-list .pick-list-count {
      display: inline-block;
      font-size: 12px;
      font-weight: 400;
      color: #74788D;
      line-height: 28px; }
      .review-connect-table .review-pick-list .pick-list-count > span {
        font-weight: 700;
        color: #5664D2; }
    .review-connect-table .review-pick-list .choice-num-view {
      display: inline-block;
      float: right; }
      .review-connect-table .review-pick-list .choice-num-view > select {
        width: 112px;
        height: 28px;
        border: 1px solid #CED4DA;
        box-sizing: border-box;
        padding: 0 20px 0 7px;
        background-position: right 7px center; }
  .review-connect-table .btn-connect {
    position: relative;
    display: block;
    width: 100%;
    border: 1px solid #E8ECF4;
    border-bottom: none;
    box-sizing: border-box;
    padding: 8px 10px; }
    .review-connect-table .btn-connect .connect-del {
      position: relative;
      display: inline-block;
      height: 28px;
      padding: 0 8px 0 20px;
      border: 1px solid #343A40;
      box-sizing: border-box;
      border-radius: 5px; }
      .review-connect-table .btn-connect .connect-del .nd-icon {
        position: absolute;
        top: 50%;
        left: 5px;
        margin-top: -8px;
        width: 16px;
        height: 16px;
        color: #FF3D60; }
      .review-connect-table .btn-connect .connect-del > span {
        font-size: 13px;
        font-weight: 400;
        color: #000000;
        line-height: 28px; }
    .review-connect-table .btn-connect .connect-add {
      position: absolute;
      top: 50%;
      right: 10px;
      margin-top: -14px;
      padding: 0 12px 0 32px; }
      .review-connect-table .btn-connect .connect-add .nd-icon {
        position: absolute;
        top: 50%;
        left: 12px;
        margin-top: -7px;
        width: 14px;
        height: 14px;
        color: #ffffff; }
      .review-connect-table .btn-connect .connect-add > span {
        font-size: 13px;
        font-weight: 400;
        color: #ffffff;
        vertical-align: middle;
        line-height: 28px; }
  .review-connect-table table tbody td {
    text-align: center;
    padding: 10px 20px; }
    .review-connect-table table tbody td .product-connect-wrap .product-content {
      display: inline-block;
      height: 26px;
      padding: 0 4px;
      background: #DADBDC;
      border-radius: 5px;
      box-sizing: border-box;
      text-align: center;
      line-height: 26px;
      margin: 0 2px;
      font-size: 13px;
      font-weight: 400;
      color: #000000;
      vertical-align: middle; }
    .review-connect-table table tbody td .product-connect-wrap .product-content:nth-child(1) {
      margin-left: 0; }
    .review-connect-table table tbody td .product-connect-wrap .product-connect {
      position: relative;
      display: inline-block;
      width: 20px;
      height: 20px;
      background: #4285F433;
      border-radius: 5px;
      text-align: center;
      vertical-align: middle;
      margin: 0 2px; }
      .review-connect-table table tbody td .product-connect-wrap .product-connect .nd-icon {
        display: inline-block;
        width: 12px;
        height: 12px;
        color: #4285F4; }
    .review-connect-table table tbody td .product-connect-wrap .product-connect.group .nd-icon.left {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%); }
    .review-connect-table table tbody td .product-connect-wrap .product-connect.group .nd-icon.right {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%); }
    .review-connect-table table tbody td .product-connect-wrap .product-connect.one-way .nd-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
    .review-connect-table table tbody td .btn-connect-modify {
      display: inline-block;
      height: 28px;
      padding: 0 8px;
      text-align: center;
      border: 1px solid #343A40;
      border-radius: 5px;
      font-size: 13px;
      font-weight: 400;
      color: #000000; }
  .review-connect-table table tbody td:nth-child(4) {
    padding: 10px; }
  .review-connect-table .pagination {
    padding: 30px 0 0 0; }

/* reviewList */
.review-search-box {
  position: relative;
  width: 100%;
  padding: 0 0 20px 0; }
  .review-search-box .btn-toggle {
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -21px;
    margin-bottom: -21px;
    background-color: #ffffff;
    padding: 2px;
    -moz-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 13px 10px 0 rgba(0, 0, 0, 0.1);
    border-radius: 3px; }
  .review-search-box input[type="checkbox"] {
    position: relative;
    display: inline-block;
    background: url("../../../images/img/input_checkbox.svg") no-repeat 0 0;
    width: 16px;
    height: 16px;
    -webkit-appearance: none;
    margin: 0 5px 0 0;
    cursor: pointer;
    vertical-align: middle; }
  .review-search-box input[type="checkbox"]:checked {
    background: url("../../../images/img/input_checkbox_on.svg") no-repeat 0 0; }
  .review-search-box .search-input-box {
    display: block;
    padding: 0 0 7px; }
    .review-search-box .search-input-box > span {
      display: inline-block;
      width: 73px;
      vertical-align: middle;
      font-size: 13px;
      font-weight: 400;
      color: #000000; }
    .review-search-box .search-input-box .link-widget {
      margin-left: 10px;
      width: auto; }
      .review-search-box .search-input-box .link-widget a {
        color: #4285F4; }
    .review-search-box .search-input-box .search-type {
      display: inline-block;
      width: 155px;
      height: 38px;
      border: 1px solid #CED4DA;
      box-sizing: border-box;
      padding: 0 25px 0 12px;
      font-size: 13px;
      font-weight: 400;
      color: #000000;
      line-height: 38px;
      vertical-align: middle;
      cursor: pointer; }
    .review-search-box .search-input-box .search-input {
      display: inline-block;
      width: 300px;
      height: 38px;
      border: 1px solid #CED4DA;
      box-sizing: border-box;
      margin: 0 0 0 10px;
      padding: 0 12px;
      font-size: 13px;
      font-weight: 400;
      color: #000000;
      line-height: 38px;
      vertical-align: middle; }
  .review-search-box .calendar-box {
    display: block;
    padding: 7px 0 10px; }
    .review-search-box .calendar-box > span {
      display: inline-block;
      width: 73px;
      vertical-align: middle;
      font-size: 13px;
      font-weight: 400;
      color: #000000; }
    .review-search-box .calendar-box .calendar-wrap {
      display: inline-block;
      font-size: 13px;
      font-weight: 400;
      color: #000000;
      vertical-align: middle; }
    .review-search-box .calendar-box .search-calendar {
      position: relative;
      display: inline-block;
      width: 220px;
      height: 38px;
      border: 1px solid #CED4DA;
      box-sizing: border-box;
      padding: 0 12px;
      margin: 0 5px 0 0;
      font-size: 13px;
      font-weight: 400;
      color: #000000;
      line-height: 38px;
      background: url("../../../images/img/icon_calendar.svg") no-repeat right 12px center;
      cursor: pointer; }
    .review-search-box .calendar-box .search-calendar.end {
      margin: 0 0 0 5px; }
  .review-search-box .detail-filter-box {
    position: relative;
    padding: 10px 0; }
    .review-search-box .detail-filter-box table {
      width: 100%;
      border: 1px solid #EFF2F7;
      box-sizing: border-box;
      text-align: left;
      border-collapse: collapse; }
      .review-search-box .detail-filter-box table tr {
        width: 100%;
        height: 46px; }
        .review-search-box .detail-filter-box table tr th {
          width: 137px;
          font-size: 13px;
          font-weight: 400;
          color: #343A40;
          border: 1px solid #EFF2F7;
          padding: 0 0 0 20px;
          box-sizing: border-box; }
        .review-search-box .detail-filter-box table tr td {
          font-size: 13px;
          font-weight: 400;
          color: #343A40;
          border: 1px solid #EFF2F7;
          padding: 0 0 0 20px; }
          .review-search-box .detail-filter-box table tr td label {
            position: relative;
            display: inline-block;
            padding: 0 0 0 20px;
            cursor: pointer;
            vertical-align: middle; }
            .review-search-box .detail-filter-box table tr td label > span {
              vertical-align: middle; }
          .review-search-box .detail-filter-box table tr td > label:nth-child(1) {
            padding-left: 0; }
          .review-search-box .detail-filter-box table tr td label.review-star span {
            display: inline-block;
            width: 60px;
            height: 11px; }
          .review-search-box .detail-filter-box table tr td label.review-star.star05 span {
            background: url("../../../images/img/icon_star5.svg") no-repeat 0 0; }
          .review-search-box .detail-filter-box table tr td label.review-star.star04 span {
            background: url("../../../images/img/icon_star4.svg") no-repeat 0 0; }
          .review-search-box .detail-filter-box table tr td label.review-star.star03 span {
            background: url("../../../images/img/icon_star3.svg") no-repeat 0 0; }
          .review-search-box .detail-filter-box table tr td label.review-star.star02 span {
            background: url("../../../images/img/icon_star2.svg") no-repeat 0 0; }
          .review-search-box .detail-filter-box table tr td label.review-star.star01 span {
            background: url("../../../images/img/icon_star1.svg") no-repeat 0 0; }
  .review-search-box .button-area {
    display: block;
    padding: 10px 0 0 0; }
    .review-search-box .button-area .search-filter-toggle {
      position: relative;
      display: inline-block;
      width: 94px;
      height: 38px;
      border-radius: 5px;
      font-size: 13px;
      font-weight: 400;
      color: #000000;
      padding: 0 31px 0 0;
      text-align: right;
      background: #EFF2F7;
      line-height: 38px;
      box-sizing: border-box;
      vertical-align: middle; }
    .review-search-box .button-area .search-filter-toggle:before {
      content: "";
      position: absolute;
      top: 50%;
      right: 12px;
      margin-top: -8px;
      width: 16px;
      height: 16px;
      background: url("../../../images/img/icon_up_arrow.svg") no-repeat 0 0;
      background-size: 100%;
      -ms-transform: rotateZ(180deg);
      -webkit-transform: rotateZ(180deg);
      transform: rotateZ(180deg); }
    .review-search-box .button-area .search-filter-toggle.open:before {
      -ms-transform: rotateZ(0);
      -webkit-transform: rotateZ(0);
      transform: rotateZ(0); }
    .review-search-box .button-area .btn_reset {
      display: inline-block;
      width: 88px;
      height: 38px;
      border-radius: 5px;
      font-size: 13px;
      font-weight: 400;
      color: #000000;
      border: 1px solid #EFF2F7;
      box-sizing: border-box;
      margin: 0 0 0 10px;
      line-height: 38px;
      vertical-align: middle;
      text-align: center; }

.review-list-box {
  margin: 20px 0 0 0; }
  .review-list-box table {
    width: 100%;
    border: 1px solid #EFF2F7;
    box-sizing: border-box;
    text-align: left;
    border-collapse: collapse; }
    .review-list-box table thead {
      width: 100%;
      height: 46px;
      background: #EFF2F7;
      text-align: center; }
      .review-list-box table thead th {
        font-size: 13px;
        font-weight: 500;
        color: #000000;
        padding: 0 21px;
        border: none; }
        .review-list-box table thead th .custom-control {
          display: inline-block; }
    .review-list-box table tbody td {
      min-height: auto;
      padding: 0;
      display: table-cell;
      border: 1px solid #EFF2F7; }
      .review-list-box table tbody td .custom-control {
        display: inline-block; }
      .review-list-box table tbody td .review-info-wrap {
        min-width: 98px;
        padding: 21px; }
        .review-list-box table tbody td .review-info-wrap .review-num {
          font-size: 13px;
          font-weight: 400;
          color: #000000;
          padding: 0 0 7px 0; }
        .review-list-box table tbody td .review-info-wrap .user-name {
          position: relative;
          font-size: 14px;
          font-weight: 400;
          color: #000000;
          margin: 0 0 10px 0;
          vertical-align: middle;
          white-space: nowrap; }
          .review-list-box table tbody td .review-info-wrap .user-name .member-icon {
            position: relative;
            display: inline-block;
            height: 18px;
            padding: 0 4px;
            box-sizing: border-box;
            text-align: center;
            font-size: 11px;
            font-weight: 400;
            line-height: 18px;
            margin: 0 0 0 5px;
            vertical-align: top; }
          .review-list-box table tbody td .review-info-wrap .user-name .member-icon.npay-member {
            color: #5664D2;
            border: 1px solid #5664D2; }
          .review-list-box table tbody td .review-info-wrap .user-name .member-icon.non-member {
            color: #343A40;
            border: 1px solid #343A40; }
          .review-list-box table tbody td .review-info-wrap .user-name .member-icon.member {
            color: #1CBB8C;
            border: 1px solid #1CBB8C; }
          .review-list-box table tbody td .review-info-wrap .user-name .member-icon.admin {
            color: #FF3D60;
            border: 1px solid #FF3D60; }
        .review-list-box table tbody td .review-info-wrap .review-type > span {
          display: inline-block;
          height: 18px;
          padding: 0 4px;
          background: #4285F4;
          text-align: center;
          font-size: 11px;
          font-weight: 400;
          color: #ffffff;
          line-height: 18px;
          margin: 0 0 10px 0; }
        .review-list-box table tbody td .review-info-wrap .review-history ul li {
          display: block;
          font-size: 11px;
          font-weight: 400;
          color: #999999; }
        .review-list-box table tbody td .review-info-wrap .review-history .history-more {
          display: block;
          padding: 7px 0 0 0; }
          .review-list-box table tbody td .review-info-wrap .review-history .history-more .btn-history-more {
            display: block;
            width: 20px;
            height: 14px;
            background: #D9E7FD;
            border-radius: 5px;
            text-align: center;
            line-height: 14px; }
            .review-list-box table tbody td .review-info-wrap .review-history .history-more .btn-history-more .nd-icon {
              width: 16px;
              height: 16px;
              color: #4285F4;
              -ms-transform: rotateZ(0);
              -webkit-transform: rotateZ(0);
              transform: rotateZ(0); }
        .review-list-box table tbody td .review-info-wrap .review-history.open .history-more .btn-history-more .nd-icon {
          -ms-transform: rotateZ(180deg);
          -webkit-transform: rotateZ(180deg);
          transform: rotateZ(180deg); }
      .review-list-box table tbody td .review-contents-wrap .order-product-info {
        position: relative;
        padding: 21px;
        border-bottom: 1px solid #EFF2F7; }
        .review-list-box table tbody td .review-contents-wrap .order-product-info .product-thumb {
          width: 30px;
          height: 30px;
          vertical-align: middle;
          display: inline-block;
          margin-right: 7px;
          cursor: pointer; }
          .review-list-box table tbody td .review-contents-wrap .order-product-info .product-thumb img {
            width: 100%;
            height: 100%; }
        .review-list-box table tbody td .review-contents-wrap .order-product-info .product-info {
          display: inline-block;
          vertical-align: middle;
          cursor: pointer; }
          .review-list-box table tbody td .review-contents-wrap .order-product-info .product-info .product-name {
            font-size: 12px;
            font-weight: 400;
            color: #000000;
            display: inline-block; }
          .review-list-box table tbody td .review-contents-wrap .order-product-info .product-info .product-option {
            display: inline-block;
            font-size: 12px;
            font-weight: 400;
            color: #999999;
            padding: 0 0 0 10px; }
        .review-list-box table tbody td .review-contents-wrap .order-product-info .product-info-more {
          position: absolute;
          top: 21px;
          right: 21px; }
          .review-list-box table tbody td .review-contents-wrap .order-product-info .product-info-more .btn-prdinfo-more {
            font-size: 12px;
            font-weight: 400;
            color: #000000;
            display: inline-block;
            vertical-align: middle; }
            .review-list-box table tbody td .review-contents-wrap .order-product-info .product-info-more .btn-prdinfo-more .nd-icon {
              display: inline-block;
              vertical-align: top;
              width: 16px;
              height: 16px;
              color: #000000;
              -ms-transform: rotateZ(0);
              -webkit-transform: rotateZ(0);
              transform: rotateZ(0); }
      .review-list-box table tbody td .review-contents-wrap .order-product-info.open .product-thumb {
        display: inline-block; }
      .review-list-box table tbody td .review-contents-wrap .order-product-info.open .product-info {
        padding: 0 0 0 10px; }
        .review-list-box table tbody td .review-contents-wrap .order-product-info.open .product-info .product-option {
          display: block;
          padding: 8px 0 0 0; }
      .review-list-box table tbody td .review-contents-wrap .order-product-info.open .product-info-more .btn-prdinfo-more .nd-icon {
        -ms-transform: rotateZ(180deg);
        -webkit-transform: rotateZ(180deg);
        transform: rotateZ(180deg); }
      .review-list-box table tbody td .review-contents-wrap .review-contents {
        position: relative;
        padding: 27px 21px 18px; }
        .review-list-box table tbody td .review-contents-wrap .review-contents .review-contents-box {
          position: relative;
          width: 100%;
          overflow: hidden; }
        .review-list-box table tbody td .review-contents-wrap .review-contents .review-contents-box:after {
          content: "";
          display: block;
          clear: both; }
        .review-list-box table tbody td .review-contents-wrap .review-contents .review-contents-area {
          position: relative;
          width: 80%;
          float: left; }
          .review-list-box table tbody td .review-contents-wrap .review-contents .review-contents-area .review-contents-top {
            display: block;
            padding: 0 0 15px 0; }
            .review-list-box table tbody td .review-contents-wrap .review-contents .review-contents-area .review-contents-top .review-star {
              display: inline-block;
              padding: 0 8px 0 0; }
              .review-list-box table tbody td .review-contents-wrap .review-contents .review-contents-area .review-contents-top .review-star > span {
                display: block;
                width: 60px;
                height: 11px;
                background: url("../../../images/img/icon_star3.svg") no-repeat 0 0;
                background-size: cover; }
            .review-list-box table tbody td .review-contents-wrap .review-contents .review-contents-area .review-contents-top .review-star.star01 > span {
              background: url("../../../images/img/icon_star1.svg") no-repeat 0 0; }
            .review-list-box table tbody td .review-contents-wrap .review-contents .review-contents-area .review-contents-top .review-star.star02 > span {
              background: url("../../../images/img/icon_star2.svg") no-repeat 0 0; }
            .review-list-box table tbody td .review-contents-wrap .review-contents .review-contents-area .review-contents-top .review-star.star03 > span {
              background: url("../../../images/img/icon_star3.svg") no-repeat 0 0; }
            .review-list-box table tbody td .review-contents-wrap .review-contents .review-contents-area .review-contents-top .review-star.star04 > span {
              background: url("../../../images/img/icon_star4.svg") no-repeat 0 0; }
            .review-list-box table tbody td .review-contents-wrap .review-contents .review-contents-area .review-contents-top .review-star.star05 > span {
              background: url("../../../images/img/icon_star5.svg") no-repeat 0 0; }
            .review-list-box table tbody td .review-contents-wrap .review-contents .review-contents-area .review-contents-top .review-write-date {
              display: inline-block;
              font-size: 11px;
              font-weight: 400;
              color: #000000; }
            .review-list-box table tbody td .review-contents-wrap .review-contents .review-contents-area .review-contents-top .review-content-length {
              float: right;
              font-size: 11px;
              font-weight: 400;
              color: #999999; }
          .review-list-box table tbody td .review-contents-wrap .review-contents .review-contents-area .review-contents-top:after {
            content: "";
            display: block;
            clear: both; }
          .review-list-box table tbody td .review-contents-wrap .review-contents .review-contents-area .review-content {
            font-size: 13px;
            font-weight: 400;
            color: #000000;
            height: 70px;
            overflow: hidden;
            overflow-y: auto;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            margin: 0 0 10px 0; }
            .review-list-box table tbody td .review-contents-wrap .review-contents .review-contents-area .review-content .review-content-textarea {
              display: inline-block;
              width: calc(100% - 100px);
              height: 70px;
              border: 1px solid #DDDDDD;
              box-sizing: border-box;
              padding: 13px 20px 11px; }
            .review-list-box table tbody td .review-contents-wrap .review-contents .review-contents-area .review-content .btn-review-content-modify {
              width: 70px;
              height: 70px;
              border: 1px solid #dddddd;
              border-left: none;
              text-align: center;
              vertical-align: middle;
              box-sizing: border-box;
              font-size: 13px;
              font-weight: 400;
              color: #000000; }
          .review-list-box table tbody td .review-contents-wrap .review-contents .review-contents-area .review-status {
            display: block;
            padding: 0 0 10px 0; }
            .review-list-box table tbody td .review-contents-wrap .review-contents .review-contents-area .review-status > div {
              display: inline-block;
              font-size: 11px;
              font-weight: 400;
              color: #000000;
              padding: 0 5px 0 0; }
            .review-list-box table tbody td .review-contents-wrap .review-contents .review-contents-area .review-status > div:last-child {
              padding: 0; }
        .review-list-box table tbody td .review-contents-wrap .review-contents .review-photo-area {
          float: right;
          position: relative;
          width: 20%;
          text-align: right; }
          .review-list-box table tbody td .review-contents-wrap .review-contents .review-photo-area .review-detail-more {
            display: block;
            padding: 0 0 12px 0; }
            .review-list-box table tbody td .review-contents-wrap .review-contents .review-photo-area .review-detail-more .btn-review-detail-more {
              font-size: 12px;
              font-weight: 400;
              color: #000000;
              display: inline-block;
              vertical-align: middle; }
              .review-list-box table tbody td .review-contents-wrap .review-contents .review-photo-area .review-detail-more .btn-review-detail-more .nd-icon {
                display: inline-block;
                vertical-align: top;
                width: 16px;
                height: 16px;
                color: #000000; }
          .review-list-box table tbody td .review-contents-wrap .review-contents .review-photo-area .review-photo {
            width: 200px;
            height: 62px;
            overflow: hidden;
            overflow-x: auto; }
            .review-list-box table tbody td .review-contents-wrap .review-contents .review-photo-area .review-photo ul {
              text-align: left;
              white-space: nowrap; }
              .review-list-box table tbody td .review-contents-wrap .review-contents .review-photo-area .review-photo ul li {
                position: relative;
                display: inline-block;
                width: 42px;
                height: 42px;
                margin: 0 0 0 4px; }
                .review-list-box table tbody td .review-contents-wrap .review-contents .review-photo-area .review-photo ul li .dim {
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  text-align: center;
                  background: #000000;
                  opacity: 50%;
                  font-size: 12px;
                  font-weight: 400;
                  color: #ffffff;
                  vertical-align: middle;
                  display: table; }
                  .review-list-box table tbody td .review-contents-wrap .review-contents .review-photo-area .review-photo ul li .dim > span {
                    display: table-cell;
                    vertical-align: middle; }
                .review-list-box table tbody td .review-contents-wrap .review-contents .review-photo-area .review-photo ul li img {
                  width: 100%; }
        .review-list-box table tbody td .review-contents-wrap .review-contents .review-comment {
          position: relative;
          width: 100%;
          padding-top: 30px; }
          .review-list-box table tbody td .review-contents-wrap .review-contents .review-comment textarea {
            display: inline-block;
            width: calc(100% - 140px);
            height: 80px;
            border: 1px solid #DDDDDD;
            box-sizing: border-box;
            padding: 13px 20px 11px; }
          .review-list-box table tbody td .review-contents-wrap .review-contents .review-comment .btn-smart-correct {
            width: 70px;
            height: 80px;
            border: 1px solid #dddddd;
            border-left: none;
            text-align: center;
            vertical-align: middle;
            box-sizing: border-box;
            font-size: 13px;
            font-weight: 400;
            color: #000000; }
          .review-list-box table tbody td .review-contents-wrap .review-contents .review-comment .btn-review-comment {
            width: 70px;
            height: 80px;
            border: 1px solid #dddddd;
            border-left: none;
            text-align: center;
            vertical-align: middle;
            box-sizing: border-box;
            font-size: 13px;
            font-weight: 400;
            color: #000000; }
          .review-list-box table tbody td .review-contents-wrap .review-contents .review-comment .btn-review-comment-top {
            position: absolute;
            top: 0;
            left: 0px;
            border-bottom: 1px solid #dddddd;
            cursor: pointer; }
          .review-list-box table tbody td .review-contents-wrap .review-contents .review-comment .btn-erase {
            position: absolute;
            right: 0;
            top: 0; }
        .review-list-box table tbody td .review-contents-wrap .review-contents .review-comment.isModify textarea {
          background-color: #f5f6f8; }
        .review-list-box table tbody td .review-contents-wrap .review-contents .review-contents:after {
          content: "";
          display: block;
          clear: both; }
      .review-list-box table tbody td .review-widget-wrap {
        position: relative;
        padding: 21px; }
        .review-list-box table tbody td .review-widget-wrap .review-widget-list {
          display: block;
          padding: 0 0 10px 0; }
          .review-list-box table tbody td .review-widget-wrap .review-widget-list ul {
            display: block; }
            .review-list-box table tbody td .review-widget-wrap .review-widget-list ul li {
              display: block;
              padding: 0 0 5px; }
              .review-list-box table tbody td .review-widget-wrap .review-widget-list ul li .review-widget {
                position: relative;
                display: inline-block;
                background: #E0E3F7;
                border-radius: 5px;
                font-size: 12px;
                font-weight: 700;
                color: #5664D2;
                padding: 3px 31px 3px 10px; }
                .review-list-box table tbody td .review-widget-wrap .review-widget-list ul li .review-widget .review-widget-tit {
                  display: inline-block;
                  white-space: nowrap; }
                .review-list-box table tbody td .review-widget-wrap .review-widget-list ul li .review-widget .review-widget-del {
                  position: absolute;
                  top: 50%;
                  right: 8px;
                  margin-top: -8px;
                  cursor: pointer; }
                  .review-list-box table tbody td .review-widget-wrap .review-widget-list ul li .review-widget .review-widget-del .nd-icon {
                    width: 16px;
                    height: 16px;
                    color: #5664D2; }
            .review-list-box table tbody td .review-widget-wrap .review-widget-list ul li:last-child {
              padding: 0; }
        .review-list-box table tbody td .review-widget-wrap .review-widget-select .widget-select {
          display: inline-block;
          width: 150px;
          height: 38px;
          border: 1px solid #CED4DA;
          box-sizing: border-box;
          padding: 0 25px 0 12px;
          font-size: 13px;
          font-weight: 400;
          color: #000000;
          line-height: 38px;
          vertical-align: middle;
          cursor: pointer; }
        .review-list-box table tbody td .review-widget-wrap .no-data {
          display: block;
          font-size: 13px;
          font-weight: 700;
          color: #4285F4;
          padding: 0 0 10px 0; }
      .review-list-box table tbody td .review-management {
        position: relative; }
        .review-list-box table tbody td .review-management .posting-state-box {
          position: relative;
          display: block;
          padding: 21px;
          border-bottom: 1px solid #EFF2F7; }
          .review-list-box table tbody td .review-management .posting-state-box .posting-state {
            font-size: 13px;
            font-weight: 400;
            color: #000000;
            padding: 0 0 10px 0; }
            .review-list-box table tbody td .review-management .posting-state-box .posting-state .posting-state-txt {
              color: #4285F4; }
          .review-list-box table tbody td .review-management .posting-state-box .posting-state-icon {
            position: absolute;
            top: 21px;
            right: 21px; }
            .review-list-box table tbody td .review-management .posting-state-box .posting-state-icon > a {
              display: inline-block;
              width: 20px;
              height: 20px;
              background: #D9E7FD;
              border-radius: 5px;
              text-align: center; }
              .review-list-box table tbody td .review-management .posting-state-box .posting-state-icon > a .nd-icon {
                width: 16px;
                height: 16px;
                color: #4285F4;
                vertical-align: middle; }
          .review-list-box table tbody td .review-management .posting-state-box .posting-state-select .posting-select {
            display: inline-block;
            width: 210px;
            height: 38px;
            border: 1px solid #CED4DA;
            box-sizing: border-box;
            padding: 0 25px 0 12px;
            font-size: 13px;
            font-weight: 400;
            color: #000000;
            line-height: 38px;
            vertical-align: middle;
            cursor: pointer; }
        .review-list-box table tbody td .review-management .benefits-state-box {
          position: relative;
          display: block;
          padding: 21px;
          border-bottom: 1px solid #EFF2F7; }
          .review-list-box table tbody td .review-management .benefits-state-box .benefits-state {
            font-size: 13px;
            font-weight: 400;
            color: #000000;
            padding: 0 0 10px 0; }
            .review-list-box table tbody td .review-management .benefits-state-box .benefits-state .benefits-state-txt {
              color: #4285F4; }
          .review-list-box table tbody td .review-management .benefits-state-box .benefits-state-icon {
            position: absolute;
            top: 21px;
            right: 21px; }
            .review-list-box table tbody td .review-management .benefits-state-box .benefits-state-icon > span {
              display: inline-block;
              width: 20px;
              height: 20px;
              border-radius: 5px;
              text-align: center;
              margin: 0 0 0 4px;
              vertical-align: middle; }
            .review-list-box table tbody td .review-management .benefits-state-box .benefits-state-icon > span:nth-child(1) {
              margin: 0; }
            .review-list-box table tbody td .review-management .benefits-state-box .benefits-state-icon > span.tooltip {
              background: url("../../../images/img/icon_tooltip.svg") no-repeat center #FF3D60; }
            .review-list-box table tbody td .review-management .benefits-state-box .benefits-state-icon > a {
              display: inline-block;
              width: 20px;
              height: 20px;
              border-radius: 5px;
              text-align: center;
              margin: 0 0 0 4px;
              vertical-align: middle;
              background: #D9E7FD; }
              .review-list-box table tbody td .review-management .benefits-state-box .benefits-state-icon > a .nd-icon {
                width: 16px;
                height: 16px;
                color: #4285F4;
                vertical-align: middle; }
          .review-list-box table tbody td .review-management .benefits-state-box .benefits-state-select .benefits-select {
            display: inline-block;
            width: 210px;
            height: 38px;
            border: 1px solid #CED4DA;
            box-sizing: border-box;
            padding: 0 25px 0 12px;
            font-size: 13px;
            font-weight: 400;
            color: #000000;
            line-height: 38px;
            vertical-align: middle;
            cursor: pointer; }
        .review-list-box table tbody td .review-management .review-management-tool {
          position: relative; }
          .review-list-box table tbody td .review-management .review-management-tool ul {
            display: block; }
            .review-list-box table tbody td .review-management .review-management-tool ul li {
              display: inline-block;
              width: 25%;
              height: 36px;
              border-right: 1px solid #EFF2F7;
              border-bottom: 1px solid #EFF2F7;
              box-sizing: border-box;
              text-align: center;
              vertical-align: middle;
              line-height: 36px;
              cursor: pointer; }
              .review-list-box table tbody td .review-management .review-management-tool ul li .nd-icon {
                vertical-align: middle; }
            .review-list-box table tbody td .review-management .review-management-tool ul li.disabled {
              cursor: not-allowed; }
              .review-list-box table tbody td .review-management .review-management-tool ul li.disabled .nd-icon {
                color: #CED4DA; }
            .review-list-box table tbody td .review-management .review-management-tool ul li:last-child {
              border-right: none; }
            .review-list-box table tbody td .review-management .review-management-tool ul .li.review-modify .nd-icon {
              width: 17px;
              height: 17px; }
            .review-list-box table tbody td .review-management .review-management-tool ul .li.review-memo .nd-icon {
              width: 18px;
              height: 18px; }
      .review-list-box table tbody td td:nth-child(1) {
        padding: 21px 21px 0; }

/* reviewWright */
.review-write-box .section-box:nth-child(1) {
  margin: 0 0 30px 0; }

.review-write-box .btn-search {
  position: relative;
  right: auto;
  bottom: auto;
  padding: 20px 0 10px;
  text-align: center; }

.product-search-box .search-input-box .search-type {
  display: inline-block;
  width: 300px;
  height: 38px;
  border: 1px solid #CED4DA;
  box-sizing: border-box;
  padding: 0 25px 0 12px;
  font-size: 13px;
  font-weight: 400;
  color: #000000;
  line-height: 38px;
  vertical-align: middle;
  cursor: pointer; }

.product-search-box .search-input-box .search-input {
  display: inline-block;
  width: 300px;
  height: 38px;
  border: 1px solid #CED4DA;
  box-sizing: border-box;
  margin: 0 0 0 10px;
  padding: 0 12px;
  font-size: 13px;
  font-weight: 400;
  color: #74788D;
  line-height: 38px;
  vertical-align: middle; }

.product-search-box .check-exhibition {
  display: block;
  padding: 10px 0 0 0; }
  .product-search-box .check-exhibition > label {
    cursor: pointer; }
    .product-search-box .check-exhibition > label input[type="checkbox"] {
      position: relative;
      display: inline-block;
      background: url("../../../images/img/input_checkbox.svg") no-repeat 0 0;
      width: 16px;
      height: 16px;
      -webkit-appearance: none;
      margin: 0 5px 0 0;
      cursor: pointer;
      vertical-align: middle; }
    .product-search-box .check-exhibition > label input[type="checkbox"]:checked {
      background: url("../../../images/img/input_checkbox_on.svg") no-repeat 0 0; }
    .product-search-box .check-exhibition > label > span {
      vertical-align: middle;
      font-size: 13px;
      font-weight: 400;
      color: #343A40; }

.product-list-box {
  margin: 0 0 20px 0; }
  .product-list-box .product-pick-list {
    display: block;
    padding: 0 0 10px 0; }
    .product-list-box .product-pick-list .pick-list-count {
      display: inline-block;
      font-size: 12px;
      font-weight: 400;
      color: #74788D;
      line-height: 28px; }
      .product-list-box .product-pick-list .pick-list-count > span {
        font-weight: 700;
        color: #5664D2; }
    .product-list-box .product-pick-list .choice-num-view {
      display: inline-block;
      float: right; }
    .product-list-box .product-pick-list select {
      width: 112px;
      height: 28px;
      border: 1px solid #CED4DA;
      box-sizing: border-box;
      padding: 0 20px 0 7px;
      background-position: right 7px center; }
  .product-list-box .product-pick-list:after {
    content: "";
    display: block;
    clear: both; }
  .product-list-box table thead tr th {
    padding: 0; }
  .product-list-box table tbody tr td {
    font-size: 14px;
    text-align: center;
    padding: 20px; }
    .product-list-box table tbody tr td .product-thumb {
      display: block;
      width: 66px;
      height: 66px;
      background: #DDDDDD; }
      .product-list-box table tbody tr td .product-thumb img {
        width: 100%;
        height: 100%; }
    .product-list-box table tbody tr td .product-title {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical; }
    .product-list-box table tbody tr td .link-naverstore-search {
      margin-top: 7px;
      text-align: right;
      font-size: 11px; }
    .product-list-box table tbody tr td .review-normal {
      display: block;
      padding: 0 0 3px 0; }
    .product-list-box table tbody tr td .review-sns {
      display: block;
      padding: 3px 0 0 0; }
  .product-list-box table tbody tr td:nth-child(3) {
    padding: 10px; }
  .product-list-box .btn-area {
    display: block;
    padding: 10px 0 0 0;
    text-align: right; }
    .product-list-box .btn-area .btn-file-upload {
      min-width: 78px;
      height: 28px;
      padding: 0 8px;
      background: #343A40;
      border-radius: 5px;
      text-align: center;
      font-size: 13px;
      font-weight: 400;
      color: #ffffff;
      line-height: 28px;
      vertical-align: middle; }

@media (min-width: 992px) {
  .isReviewHistoryModal.modal-lg {
    max-width: 960px; } }

.history-window-popup .history-popup-contents {
  position: relative; }

.history-window-popup .history-popup-contents .popup-contents-top {
  margin: 0 0 30px 0; }

.history-window-popup .history-popup-contents h4 {
  font-size: 15px;
  padding: 17px 0 7px 0; }

.history-window-popup .history-popup-contents .popup-contents-top table {
  width: 100%;
  border-collapse: collapse; }

.history-window-popup .history-popup-contents .popup-contents-top tr {
  font-size: 0;
  text-align: center; }

.history-window-popup .history-popup-contents .popup-contents-top tr th {
  width: auto;
  font-size: 13px;
  font-weight: 400;
  color: #000000;
  padding: 10px;
  background: #EFF2F7;
  border: 1px solid #EAEAEA; }

.history-window-popup .history-popup-contents .popup-contents-top tr td {
  font-size: 13px;
  font-weight: 400;
  color: #000000;
  border: 1px solid #EAEAEA;
  padding: 10px; }

.history-window-popup .history-popup-contents .popup-contents-top tr td .member-icon {
  position: relative;
  display: inline-block;
  height: 18px;
  padding: 0 4px;
  box-sizing: border-box;
  text-align: center;
  font-size: 11px;
  font-weight: 400;
  line-height: 18px;
  margin: 0 0 0 8px;
  vertical-align: top; }

.history-window-popup .history-popup-contents .popup-contents-top tr td .npay-member {
  color: #5664D2;
  border: 1px solid #5664D2; }

.history-window-popup .history-popup-contents .popup-contents-top tr td.review-type {
  font-size: 0; }

.history-window-popup .history-popup-contents .popup-contents-top tr td.review-type span {
  display: inline-block;
  height: 18px;
  padding: 0 4px;
  background: #4285F4;
  text-align: center;
  font-size: 11px;
  font-weight: 400;
  color: #ffffff;
  line-height: 18px; }

.history-window-popup .history-popup-contents .popup-contents-main {
  margin: 0 0 20px 0; }

.history-window-popup .history-popup-contents .popup-contents-main table {
  width: 100%;
  border-collapse: collapse; }

.history-window-popup .history-popup-contents .popup-contents-main table thead th {
  height: 46px;
  background: #EFF2F7;
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  color: #000000;
  padding: 0; }

.history-window-popup .history-popup-contents .popup-contents-main table tbody td {
  padding: 21px;
  border: 1px solid #EAEAEA;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  color: #000000; }

.history-window-popup .history-popup-contents .popup-contents-main table tbody td .posting-state {
  font-weight: 700;
  color: #4285F4; }

.history-window-popup .history-popup-contents .popup-contents-main table tbody td .posting-select {
  display: inline-block;
  width: 138px;
  height: 38px;
  border: 1px solid #CED4DA;
  box-sizing: border-box;
  padding: 0 25px 0 12px;
  font-size: 13px;
  font-weight: 400;
  color: #000000;
  line-height: 38px;
  vertical-align: middle;
  margin: 8px 0 0 0;
  cursor: pointer; }

.history-window-popup .history-popup-contents .popup-contents-main table tbody td .benefits-state {
  font-weight: 700;
  color: #4285F4; }

.history-window-popup .history-popup-contents .popup-contents-main table tbody td .benefits-state-list {
  margin: 8px 0 0 0; }

.history-window-popup .history-popup-contents .popup-contents-main table tbody td .benefits-state-list ul {
  display: block;
  font-size: 0; }

.history-window-popup .history-popup-contents .popup-contents-main table tbody td .benefits-state-list ul li {
  display: inline-block;
  padding: 7px 12px;
  border: 1px solid #EAEAEA;
  border-radius: 5px;
  margin: 0 4px 0 0;
  font-size: 12px;
  font-weight: 400;
  color: #000000;
  cursor: pointer; }

.history-window-popup .history-popup-contents .popup-contents-main table tbody td .benefits-state-list ul li:first-child {
  border: none;
  background: #5664D2;
  color: #ffffff; }

.history-window-popup .history-popup-contents .popup-contents-main table tbody td .benefits-state-list ul li:last-child {
  margin: 0; }

.history-window-popup .history-popup-contents .popup-contents-main table tbody td .btn-review-memo {
  display: block;
  width: 38px;
  height: 32px;
  background: #5664D2;
  text-align: center;
  border-radius: 5px; }

.history-window-popup .history-popup-contents .popup-contents-main table tbody td .btn-review-memo .nd-icon {
  width: 20px;
  height: 20px;
  color: #ffffff;
  vertical-align: middle; }

.history-window-popup .history-popup-contents .popup-close {
  display: block;
  font-size: 0; }

.history-window-popup .history-popup-contents .popup-close .btn-popup-close {
  display: block;
  width: 52px;
  height: 38px;
  background: #5664D2;
  border-radius: 5px;
  font-size: 13px;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  line-height: 38px;
  margin: 0 auto; }

.color-picker {
  position: relative; }

.color-picker-selecter-wrap {
  position: absolute;
  top: 41px;
  z-index: 100; }

.modal-review-write-form textarea.form-control {
  height: 120px; }

.media-uploaded-box {
  min-height: 162px; }
  .media-uploaded-box ul li {
    position: relative;
    display: inline-block;
    width: 128px;
    height: 128px;
    margin-left: 10px; }
    .media-uploaded-box ul li span.btn-media-remove {
      position: absolute;
      top: 3px;
      right: 3px;
      background-color: #ffffff;
      padding: 3px 6px 1px 6px;
      font-weight: bold;
      cursor: pointer; }

.skin-list {
  text-align: left; }

.info-msg {
  margin-top: 5px;
  float: right;
  color: #ca8816;
  clear: both; }

select {
  width: 150px;
  height: 38px;
  border: 1px solid #CED4DA;
  box-sizing: border-box;
  padding: 0 20px 0 7px;
  background-position: right 7px center; }

.form-check-padding-0 {
  padding: 0 !important; }

.popover.smart-correct-popover {
  max-width: 70%;
  width: 70%; }
  .popover.smart-correct-popover h4 {
    font-size: 16px; }
  .popover.smart-correct-popover .comment {
    font-size: 15px;
    color: #000000;
    white-space: pre-line; }
  .popover.smart-correct-popover .comment-modify {
    background-color: #d1e5fb; }

.none-label-checkbox {
  padding-left: 0 !important;
  display: inline !important; }

.review-mileage-ctrl {
  padding: 0 20px; }

.text-notice {
  font-size: 16px; }

.btns_period {
  margin-left: 10px; }

.history-window-popup {
  max-height: none;
  /* 기본 값 */ }

@media (max-height: 1300px) {
  .history-window-popup {
    max-height: 600px;
    overflow-y: scroll; } }

.notice-text h3 {
  font-size: 21px; }

.notice-text .text-red {
  color: #c00 !important; }

.notice-text ul li {
  list-style: disc;
  list-style-position: inside;
  font-size: 16px; }

.dday-count {
  color: #c00 !important; }
